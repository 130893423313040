<template>

  <div class="full-height d-flex flex-column">
    <v-container
      fluid
      style="margin-top: -58px"
    >
      <v-row
        align="center"
        no-gutters
      >
        <v-spacer />
        <div class="d-flex gap align-center">


          <v-btn
            v-if="showEdit"
            class="iconButton mx-1"
            icon
            @click.stop="openDialog"
          >
            <v-icon class="configLight">hi-pencil-square-outline</v-icon>
          </v-btn>
          <v-btn
            class="iconButton mx-1"
            icon
            @click.stop="printPageDirectly"
          >
            <v-icon class="configLight">hi-printer-outline</v-icon>
          </v-btn>
          <v-btn
            class="iconButton mx-1"
            icon
            @click.stop="printPage"
          >
            <v-icon class="configLight">hi-document-text-outline</v-icon>
          </v-btn>
          <!--   <v-btn class="iconButton mx-1" icon @click.stop="widenPDF">
            <v-icon class="configLight">hi-arrows-pointing-out-outline</v-icon>
          </v-btn>
          <v-btn class="sideBarColor" @click.stop="generate" elevation="0">
            Generate
          </v-btn>-->

        </div>
      </v-row>
    </v-container>


    <html-editor
      v-model="template"
      :dialog="editDialog"
      :onClose="closeDialog"
      :onSave="saveTemplate"
      :data="data"
      :templates="getTemplates"
    />

    <v-container
      fluid
      class="flex1 rounded-md frameInCenter scroll"
    >
      <div
        ref="printContent"
        class="pa-4"
        v-html="getBody"
      />
    </v-container>
  </div>
</template>


<script>
import { TEMPLATE } from "../store/constants";
import HtmlEditor from "./HtmlEditor.vue";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';


import {
  formatHtmlData,
  isEmpty,
  isEmptyObj,
  replaceImageSrcWithDataURL,
} from "../api/utilities";
import { mapGetters } from "vuex";
export default {
  components: { HtmlEditor },
  props: {
    viewconfig: {
      type: String,
      default: "",
    },
    object: {
      type: Object,
      default: null, //() => ({})
    },
    operationid: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: "300px",
    },
  },
  data() {
    return {
      body: null,
      editDialog: false,
      template: null,
      data: {},
    };
  },
  computed: {
    ...mapGetters(["getIsUpdating", 'getDeviceSize']),
    isMobile() {
      return this.getDeviceSize === 1
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getTemplateId() {
      if (this.object && this.object.TemplateId) {
        return this.object.TemplateId;
      }
      if (this.getViewConfig) {
        return this.getViewConfig.subViewConfig;
      }
      return null;
    },
    getLanguage() {
      if (this.object && !isEmpty(this.object.Language)) {
        return this.object.Language;
      }
      return null;
    },
    getTemplate() {
      if (this.object && this.object.TemplateId) {
        return this.object;
      }
      var language = this.getLanguage;
      var templateId = this.getTemplateId;
      console.log("Get templateId");
      if (!isEmpty(language)) {
        var languageTemplateId = templateId + "_" + language;
        var templates = this.$store.getters.getDataObjectsForObjectType(TEMPLATE);
        var templateLanguage = templates.find((o) => {
          return o.TemplateId === languageTemplateId;
        });
        if (!isEmptyObj(templateLanguage)) {
          return templateLanguage;
        }
      }
      var templates = this.$store.getters.getDataObjectsForObjectType(TEMPLATE);
      return templates.find((o) => {
        return o.TemplateId === templateId;
      });

      return null;
    },
    showEdit() {
      var viewConfig = this.getViewConfig
      return (!isEmptyObj(viewConfig) && viewConfig.objectType === TEMPLATE)
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getTemplateObjectType() {
      if (!isEmptyObj(this.getOptions) && !isEmpty(this.getOptions.ObjectType)) {
        return this.getOptions.ObjectType
      }
      return this.getTemplate ? this.getTemplate.ObjectType : null;
    },
    getTemplates() {
      const objects = this.$store.getters.getDataObjectsForObjectType(TEMPLATE);
      if (objects) {
        return objects;
      }
      return [];
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        console.log("options", this.getViewConfig.options);
        try {
          const options = JSON.parse(this.getViewConfig.options);
          return options;
        } catch (error) {
          console.error(error);
          return null;
        }
      }
      return null;
    },
    getObject() {
      if (this.object) {
        return this.object;
      } else {
        var template = this.getTemplates.find(
          (item) => item.Guid === this.selectedTemplate,
        );
        if (template) {
          return template.Body;
        }
      }
      return "";
    },
    getHtmlWidth() {
      return "1596pt"
    },
    getBody() {
      const body = this.getTemplate ? this.getTemplate.Body : null;
      if (body) {
        const templates = structuredClone(this.getTemplates);
        console.log("this.data", this.data);
        return formatHtmlData(structuredClone(body), this.data, templates);
      }
      return "";
    },
    getObjectGuidForTemplate() {
      if (!isEmptyObj(this.getOptions) && !isEmpty(this.getOptions.ObjectGuidField)) {
        return this.object[this.getOptions.ObjectGuidField]
      }
      return this.object.Guid
    },
  },
  mounted() {
    if (this.object) {
      this.updateData();
    }
  },
  watch: {
    object(value) {
      if (!isEmpty(value)) {
        this.updateData();
      }
    },
    getIsUpdating(value) {
      this.updateData();
    },
  },

  methods: {
    isEmpty,
    closeDialog() {
      this.editDialog = false;
      // saveobjec
    },
    closePreviewDialog() {
      this.previewDialog = false
    },



    printPageSave() {
      let title = "Title";
      if (isEmpty(this.object.Subject)) {
        title = this.object.Subject;
      }

      var htmltitle = "<html><head><title>" + title + "</title>"
      const printWindow = window.open('', '', 'width=950,height=1000%');
      printWindow.document.write(htmltitle);
      printWindow.document.write('<style>body { font-family: Arial, sans-serif; margin: 20px; }</style>');
      printWindow.document.write('</head><body>');
      printWindow.document.write(this.$refs.printContent.innerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close(); // Sluit het document om het te renderen


      // Adding a delay to ensure content is fully loaded
      printWindow.onload = async function () {
        setTimeout(async () => {
          const pdf = new jsPDF();

          // Retrieve the content of the printWindow
          const contentWithoutImage = printWindow.document.body.innerHTML;

          // Replace image src attributes with base64 data URLs
          const updatedContent = await replaceImageSrcWithDataURL(contentWithoutImage);

          // Inject the updated content back into the printWindow's document
          printWindow.document.body.innerHTML = updatedContent;

          // Use html2canvas to render the updated content as an image
          const canvas = await html2canvas(printWindow.document.body);

          // Convert the canvas to an image
          const imgData = canvas.toDataURL("image/png");

          // Define PDF page settings
          const imgWidth = 190; // Width of the image in the PDF (A4 width minus some margin)
          const pageHeight = 297; // A4 page height in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate the image height to maintain aspect ratio
          let heightLeft = imgHeight;

          let position = 0;

          // Add the image to the PDF
          pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          // If the image overflows, add additional pages
          while (heightLeft > 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          // Save the PDF with the desired title
          const title = "Document"; // Update this with your dynamic title if needed
          pdf.save(`${title}.pdf`);
        }, 500); // Add a delay to ensure content is fully loaded
      }
    },


    // Helper method to split content by page-break elements

    // Helper method to replace image src attributes with data URLs
    async replaceImageSrcWithDataURL(content) {
      const imgRegex = /<img[^>]+src="([^">]+)"/g;
      const matches = [...content.matchAll(imgRegex)];

      for (let match of matches) {
        const imgSrc = match[1];
        if (imgSrc.startsWith('http')) { // Only process external images
          const base64 = await this.convertImageToBase64(imgSrc);
          content = content.replace(imgSrc, base64);
        }
      }

      return content;
    },

    // Helper method to fetch image and convert it to base64
    async convertImageToBase64(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },



    openPrintPage(print) {
      let title = "Title";

      if (!isEmpty(this.object.Reference)) {
        title = this.object.Reference;
      }
      if (!isEmpty(this.object.Subject)) {
        title = this.object.Subject;
      }


      var htmltitle = "<html><head><title>" + title + "</title>";
      const printWindow = window.open('', '', 'width=950,height=1000%');
      printWindow.document.write(htmltitle);
      printWindow.document.write(`
      <style>
      body { font-family: Arial, sans-serif; margin: 20px; position: relative; }
      #closeWindow, #printButton {
        background-color: #f7f9fbdd; /* Light gray */
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        color: #0000008a;
         padding: 10px 20px;
            font-size: 14px;
      }

      ${this.isMobile ?
          `#closeWindow, #printButton {
          padding: 24px 60px;
          font-size: 36px;
          border-radius: 24px;
        }`
          :
          `#closeWindow, #printButton {
           
          }`}
      </style>`);
      printWindow.document.write('</head><body>');
      printWindow.document.write(this.$refs.printContent.innerHTML);
      printWindow.document.write(`
        <div id="actionButtons" style="display:flex; gap: 8px; position: fixed; top: 16px; right: 16px; padding: 10px; z-index: 100;">
            <button id="printButton" onclick="window.print()">Print</button>
            <button id="closeWindow" onclick="window.close()">Close</button>
        </div>
      `);
      printWindow.document.write('</body></html>');
      printWindow.document.close(); // Sluit het document om het te renderen


      printWindow.onload = async function () {
        // Hide the buttons during printing
        printWindow.document.title = title;
        const printButtons = printWindow.document.getElementById('actionButtons');
        printButtons.style.display = 'block'; // Show print button// Show close button
        printWindow.onbeforeprint = function () {
          printButtons.style.display = 'none'; // Hide the print button// Hide the close button
        };
        printWindow.onafterprint = function () {
          printButtons.style.display = 'block';
        };

        // Add a delay to ensure content is fully loaded before printing
        if (print === true) {
          setTimeout(() => {
            printWindow.print();
          }, 100); // Adjust the delay if necessary
        }
      };
    },

    printPageDirectly() {
      this.openPrintPage(true)
    },

    printPage() {
      this.openPrintPage(false)
    },


    async replaceImageSrcWithDataURL(content) {
      const imgRegex = /<img[^>]+src="([^">]+)"/g;
      const matches = [...content.matchAll(imgRegex)];

      for (let match of matches) {
        const imgSrc = match[1];
        if (imgSrc.startsWith('http')) { // Only process external images
          const base64 = await this.convertImageToBase64(imgSrc);
          content = content.replace(imgSrc, base64);
        }
      }

      return content;
    },

    // Helper method to fetch image and convert it to base64
    async convertImageToBase64(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },



    async updateData() {
      if (!isEmpty(this.object)) {
        let guid = this.getObjectGuidForTemplate
        //let guid = this.object.Guid;
        if (this.getObjectType === TEMPLATE && this.object.ObjectGuid) {
          guid = this.object.ObjectGuid;
        }
        var templateObjectType = this.getTemplateObjectType
        console.log("Dit is the testtt", templateObjectType)
        const object = this.$store.getters.getDataObjectForIdAndObjectType({
          objectId: guid,
          objectType: templateObjectType,
        });
        // if (object) {
        var objectConfig = this.$store.getters.getObjectConfigForType(
          this.getObjectType === TEMPLATE
            ? this.getTemplateObjectType
            : templateObjectType,
        );
        var payload = {
          action: "getTemplateData",
          objectConfig: objectConfig,
          object,
          viewConfig: this.getViewConfig,
          options: {
            templateId: this.getTemplateId,
          },
        };
        var result = await this.$store.dispatch(
          "launchActionForObjectAndConfig",
          payload,
        );
        if (result) {
          this.data = result;
          return;
        } else {
          let readable = null;
          if (!isEmptyObj(object)) {
            readable = this.$store.getters.getReadableObjects(
              this.getTemplateObjectType,
              object,
            );
          }
          this.data = readable || object;
          return;
        }

        // }
      }
      this.data = this.object;
    },
    openDialog() {
      console.log("Get Template")
      this.template = this.getTemplate ? this.getTemplate.Body : "";
      this.editDialog = true;
    },
    saveTemplate() {
      const oldObject = this.getTemplate;
      const object = structuredClone(oldObject);
      object.Body = this.template;
      const payload = {
        oldObject,
        object,
        objectType: TEMPLATE,
      };
      this.$store.dispatch("saveObjectForObjectType", payload);
      this.editDialog = false;
      // saveobjec
    },

    async generate() {
      if (this.getBody) {
        var that = this;
        var doc = new jsPDF("p", "pt", [793.706, 1122.52]);
        const body = structuredClone(this.getBody);
        const formatted = await replaceImageSrcWithDataURL(body);
        doc.html(formatted, {
          callback: async function (doc) {
            doc.save();
            // const pdfBlob = doc.output('blob');
            // const pdfUrl = URL.createObjectURL(pdfBlob);
            // that.pdfDataUrl = pdfUrl
            // console.log(that.pdfDataUrl)
            // doc.save();
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.frameInCenter {
  display: flex;
  justify-content: center;
  /* Horizontal alignment */
  align-items: center;
}
</style>
