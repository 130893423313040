<template>
    <div class="px-1 kingSize">
        <v-btn
            class="primary lighten-5 primary--text kingSize"
            elevation="0"
            @click="filterSheet = !filterSheet"
        >
            Advanced filter {{ getKeys > 0 ? `(${getKeys})` : '' }}
        </v-btn>
        <v-bottom-sheet v-model="filterSheet">
            <v-card
                class="d-flex flex-column"
                style="max-height: 90vh;"
            >
                <v-card-title>Advanced Filters</v-card-title>

                <div
                    class="flex-1 d-flex flex-column flex-lg-row px-6 py-2 gap-1"
                    :class="getIsMobile ? 'scroll-y' : ''"
                >
                    <div
                        v-for="customFilter, index in customFilters"
                        :key="index"
                    >

                        <div
                            v-if="customFilter.type == 'multiSelect'"
                            :style="`max-width: ${customFilter.maxWidth || 'auto'}; min-width: 220px;`"
                        >
                            <div class="d-flex justify-space-between align-center py-3"><span class="text-caption">{{
                                customFilter.field
                                    }}</span></div>
                            <v-autocomplete
                                v-if="getItems(customFilter)"
                                ref="inputRef"
                                :value="getValue(customFilter)"
                                :items="getItems(customFilter)"
                                :label="customFilter.field"
                                chips
                                filled
                                dense
                                small-chips
                                deletable-chips
                                item-text="descr"
                                item-value="value"
                                class="text-field-transparent nowrap"
                                multiple
                                clearable
                                @change="(val) => { change(customFilter, val) }"
                            >

                            </v-autocomplete>

                        </div>
                        <div
                            v-if="customFilter.type == 'multiSelectTree'"
                            :style="`max-width: ${customFilter.maxWidth || 'auto'}; min-width: 220px;`"
                        >
                            <div class="d-flex justify-space-between align-center py-3"><span class="text-caption">{{
                                customFilter.field
                                    }}</span></div>
                            <treeselect
                                :value="getValue(customFilter)"
                                :multiple="true"
                                :options="getTreeItems(customFilter)"
                                @input="(val) => { changeTree(customFilter, val) }"
                            />


                        </div>
                        <div
                            v-if="customFilter.type == 'select'"
                            :style="`max-width: ${!getIsMobile && customFilter.maxWidth ? customFilter.maxWidth : 'auto'}; min-width: 220px;`"
                        >
                            <div class="d-flex justify-space-between align-center py-3"><span class="text-caption">{{
                                customFilter.field
                                    }}</span></div>
                            <v-autocomplete
                                v-if="getItems(customFilter)"
                                ref="inputRef"
                                :value="getValue(customFilter)"
                                :items="getItems(customFilter)"
                                :label="customFilter.field"
                                filled
                                dense
                                clearable
                                item-text="descr"
                                item-value="value"
                                class="text-field-transparent"
                                @change="(val) => { change(customFilter, val) }"
                            >



                            </v-autocomplete>

                        </div>
                        <div
                            v-if="customFilter.type == 'rangeSlider'"
                            :style="`max-width: ${!getIsMobile && customFilter.maxWidth ? customFilter.maxWidth : 'auto'}; min-width: 220px;`"
                        >
                            <div class="d-flex justify-space-between align-center "><span class="text-caption">{{
                                customFilter.field
                                    }}</span><v-switch
                                    v-model="customFilter.active"
                                    @change="(val) => {
                                        customFilter.active = val
                                        change(customFilter, [getValue(customFilter)[0], getValue(customFilter)[1]])

                                    }"
                                    class="mt-0"
                                    inset
                                /></div>
                            <div class="d-flex justify-space-between align-center gap-1">

                                <v-text-field
                                    ref="inputRef"
                                    :value="getValue(customFilter)[0]"
                                    :label="`${customFilter.field} min`"
                                    class="text-field-transparent p-1"
                                    :prefix="getPrefix(customFilter)"
                                    dense
                                    filled
                                    :disabled="!customFilter.active"
                                    hide-details
                                    @blur="() => {
                                        if (getValue(customFilter)[0] < customFilter.min) {

                                            change(customFilter, [customFilter.min, getValue(customFilter)[1]])
                                        }
                                        if (getValue(customFilter)[0] > customFilter.max) {
                                            change(customFilter, [customFilter.max, getValue(customFilter)[1]])
                                        }
                                    }"
                                    @input="(val) => {
                                        let newValue = val

                                        if (!isEmpty(val) && val >= customFilter.min && val <= customFilter.max) {
                                            if (val < customFilter.min) {
                                                newValue = customFilter.min

                                            } else if (val >= customFilter.max) {
                                                newValue = customFilter.max

                                            }
                                            if (newValue >= getValue(customFilter)[1]) {
                                                change(customFilter, [newValue, newValue])
                                            } else {
                                                change(customFilter, [newValue, getValue(customFilter)[1]])
                                            }
                                        }

                                    }"
                                />
                                <v-text-field
                                    ref="inputRef"
                                    :value="getValue(customFilter)[1]"
                                    :label="`${customFilter.field} max`"
                                    class="text-field-transparent p-1"
                                    :prefix="getPrefix(customFilter)"
                                    dense
                                    filled
                                    :disabled="!customFilter.active"
                                    hide-details
                                    @blur="() => {
                                        if (getValue(customFilter)[1] < customFilter.min) {
                                            change(customFilter, [getValue(customFilter)[0], customFilter.min])
                                        }
                                        if (getValue(customFilter)[1] > customFilter.max) {
                                            change(customFilter, [getValue(customFilter)[0], customFilter.max])
                                        }
                                    }"
                                    @input="(val) => {
                                        if (!isEmpty(val) && val >= customFilter.min && val <= customFilter.max) {
                                            let newValue = val
                                            if (val <= customFilter.min) {
                                                newValue = customFilter.min

                                            } else if (val >= customFilter.max) {
                                                newValue = customFilter.max

                                            }
                                            if (newValue < getValue(customFilter)[0]) {
                                                change(customFilter, [newValue, newValue])
                                            } else {
                                                change(customFilter, [getValue(customFilter)[0], newValue])
                                            }
                                        }
                                    }"
                                />

                            </div>
                            <v-range-slider
                                style="min-width: 100%"
                                :value="getValue(customFilter)"
                                :min="customFilter.min"
                                :max="customFilter.max"
                                :step="customFilter.step"
                                :disabled="!customFilter.active"
                                @mouseup="() => {
                                    mouseUp(customFilter)

                                }"
                                @input="(val) => { input(customFilter, val) }"
                            ></v-range-slider>
                        </div>

                    </div>

                </div>
                <div class="px-6 justify-center d-flex d-md-none py-4">
                    <v-btn
                        elevation="0"
                        class="primary lighten-5 primary--text mx-1  kingSize "
                        @click="filterSheet = !filterSheet"
                    >
                        Close
                    </v-btn>
                </div>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { formatNumber, getAllTreeSubGuids, getCurrencySign, isEmpty, isEmptyArray, isEmptyObj, mapToTree } from '../../api/utilities'
export default {
    components: { Treeselect },
    props: {
        viewconfig: {
            type: String,
            default: ""
        },
        object: {
            type: Object,
            default: null
        },
        customFilters: {
            type: Array,
            default: []
        },

    },
    data() {
        return {
            localFilter: {},
            filterSheet: false
        }
    },
    computed: {
        ...mapGetters(['getCurrentObject', 'getIsMobile']),
        filter() {
            const filter = this.$store.getters.getCustomFilterForViewConfig(this.viewconfig)
            return filter
        },
        name() {
            return ''
        },
        getKeys() {
            if (Object.keys(this.localFilter)) {
                return Object.keys(this.localFilter).length
            }
            return 0
        }
    },
    mounted() {
        if (isEmpty(this.filter)) {
            this.setDefault()
        }
    },
    methods: {
        setDefault() {
            let filter = {}
            this.customFilters.map(customFilter => {
                var defaultValue = structuredClone(customFilter.defaultValue)
                if (customFilter.active !== false) {
                    if (customFilter.related && this.getCurrentObject) {
                        var relatedValue = structuredClone(customFilter.related)

                        if (typeof relatedValue === 'object' && !isEmptyObj(relatedValue)) {
                            const keys = Object.keys(relatedValue)
                            for (let index = 0; index < keys.length; index++) {
                                let field = relatedValue[keys[index]];
                                if (field && this.getCurrentObject[field]) {
                                    if (this.getCurrentObject[field] == "[]" || (Array.isArray(this.getCurrentObject[field]) && isEmptyArray(this.getCurrentObject[field]))) {
                                        relatedValue = null
                                    } else {
                                        relatedValue[keys[index]] = this.getCurrentObject[field]
                                    }

                                } else if (defaultValue && defaultValue[keys[index]]) {
                                    relatedValue[keys[index]] = defaultValue[keys[index]]
                                } else {
                                    relatedValue = null
                                }

                            }
                        } else {
                            if (relatedValue && this.getCurrentObject[relatedValue]) {
                                const field = defaultValue.replace("$related.", "");
                                defaultValue = this.getCurrentObject[field]
                            } else if (defaultValue && defaultValue[relatedValue]) {
                                relatedValue[relatedValue] = defaultValue[relatedValue]
                            }
                        }
                        if (relatedValue) {
                            filter[customFilter.field] = relatedValue
                        }

                    } else if (defaultValue) {
                        filter[customFilter.field] = defaultValue
                    }
                }

            })
            this.localFilter = structuredClone(filter)
            this.$store.commit('setCustomFilters', {
                viewConfig: this.viewconfig,
                filter
            })

        },
        isEmpty,
        mouseUp(customFilter) {
            this.change(customFilter, this.getValue(customFilter))
        },
        getItems(customFilter) {
            if (customFilter.items) {
                return customFilter.items
            }
            if (customFilter.valueList) {
                const valueList = this.$store.getters.getValueListForDropdown(customFilter.valueList)
                if (valueList) {
                    return valueList
                }
            }
            if (!isEmptyObj(customFilter.customItems) && !isEmpty(customFilter.customItems.objectType) && !isEmpty(customFilter.customItems.valueField) && !isEmpty(customFilter.customItems.descriptionField)) {
                const list = this.$store.getters.getDataObjectsForObjectType(customFilter.customItems.objectType)
                if (list) {
                    return list.map(item => {
                        return {
                            value: item[customFilter.customItems.valueField],
                            descr: item[customFilter.customItems.descriptionField]
                        }
                    })
                }
            }
            return []
        },
        getTreeItems(customFilter) {

            if (!isEmptyObj(customFilter.customItems) && !isEmpty(customFilter.customItems.objectType) && !isEmpty(customFilter.customItems.valueField) && !isEmpty(customFilter.customItems.descriptionField)) {
                const list = this.$store.getters.getDataObjectsForObjectType(customFilter.customItems.objectType)
                if (list) {
                    return mapToTree(list, customFilter.customItems.valueField, customFilter.customItems.descriptionField, customFilter.customItems.parentField)

                }
            }
            return []
        },
        mapItems(items, value) {
            if (items) {
                return items.map(item => item[value])
            }
            return items
        },
        getValue(customFilter) {

            let val = this.localFilter && structuredClone(this.localFilter[customFilter.field])

            if (customFilter.type === 'rangeSlider') {
                if (isEmpty(val)) {
                    val = customFilter.defaultValue
                }
                if (customFilter.include) {

                    return [val['$gte'] || 0, val['$lte'] || 1]
                } else {
                    return [val['$gt'] || 0, val['$lt'] || 1]
                }


            } else if (customFilter.type === 'multiSelect') {
                if (isEmpty(val)) {
                    return []
                }
                if (customFilter.all) {
                    return val['$all']
                } else if (customFilter.regex) {
                    return val && val['$regex']
                }
                else {
                    return val['$in']
                }

            } else if (customFilter.type === 'multiSelectTree' && val && val['$in']) {
                return val['$in']
            }
            else if (customFilter.in) {
                return val && val['$in']
            }

            return structuredClone(this.localFilter[customFilter.field])
        },
        input(customFilter, value) {

            if (customFilter.type === 'rangeSlider') {
                if (customFilter.include) {
                    this.localFilter[customFilter.field] = { "$gte": value[0], "$lte": value[1] }
                } else {
                    this.localFilter[customFilter.field] = { "$gt": value[0], "$lt": value[1] }
                }
            }
            else {
                this.localFilter[customFilter.field] = value
            }

        },
        changeTree(customFilter, value) {
            const items = this.getTreeItems(customFilter)
            const values = getAllTreeSubGuids(items, value, customFilter.customItems.valueField, customFilter.customItems.parentField)
            this.change(customFilter, values)
        },
        change(customFilter, value) {
            let filter = this.filter ? structuredClone(this.filter) : {}
            if (customFilter.active === false) {
                delete filter[customFilter.field]
            } else {
                if (customFilter.type === 'rangeSlider') {
                    if (customFilter.include) {
                        filter[customFilter.field] = { "$gte": value[0], "$lte": value[1] }
                    } else {
                        filter[customFilter.field] = { "$gt": value[0], "$lt": value[1] }
                    }

                }
                else if (customFilter.type === 'multiSelect' || customFilter.type === 'multiSelectTree') {
                    if (isEmptyArray(value)) {
                        delete filter[customFilter.field]
                    } else if (customFilter.all) {
                        filter[customFilter.field] = { "$all": value }
                    }
                    else {
                        filter[customFilter.field] = { "$in": value }
                    }

                } else if (customFilter.in) {
                    filter[customFilter.field] = { "$in": value }
                }
            }

            this.localFilter = structuredClone(filter || {})
            this.$store.commit('setCustomFilters', {
                viewConfig: this.viewconfig,
                filter
            })
        },
        getPrefix(customFilter) {
            if (customFilter && customFilter.format) {
                if (customFilter.format.currency) {
                    return getCurrencySign(customFilter.format.currency)
                }
            }
            return null
        },
        formatValue(value, format) {
            if (value && format) {
                if (!isEmpty(format.currency)) {
                    return formatNumber(value, format.currency)
                }
            }
            return value
        }
    }
}
</script>

<style lang="scss" scoped></style>